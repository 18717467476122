import validator from "@rjsf/validator-ajv8";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Form from "@rjsf/core";
import ReCAPTCHA from "react-google-recaptcha";
import "./App.css";

const App = () => {
  let API_URL = "https://dev.api.lms.restthecase.com/api/v1/widget/wd";

  if (process.env.REACT_APP_ENV === "prod") {
    API_URL = "https://api.lms.restthecase.com/api/v1/widget/wd";
  } else if (process.env.REACT_APP_ENV === "uat") {
    API_URL = "https://uat.api.lms.restthecase.com/api/v1/widget/wd";
  }

  const searchParam = new URLSearchParams(document.location.search);
  const id = searchParam.get("id");
  const origin = searchParam.get("o");
  const [form, setForm] = useState({ schema: {}, uiSchema: {} });
  const [captchaErr, setCaptchaErr] = useState();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();
  const recaptchaRef = React.createRef();

  const submit = (data) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      setCaptchaErr("Please click on the captcha");
      return false;
    }
    const formData = {
      formId: id,
      ...data.formData,
      captchaResp: recaptchaValue,
    };
    axios
      .post(`${API_URL}/${id}`, formData)
      .then((resp) => {
        setSuccess(true);
      })
      .catch((err) => {
        console.log(err);
        setError(
          err.response.data?.message ||
            "Something went wrong, Please try after some time"
        );
        setSuccess(false);
      });
  };
  useEffect(() => {
    if (id) {
      axios.get(`${API_URL}/${id}?origin=${origin}`).then((response) => {
        setForm(response.data);
      });
    }
  }, []);

  if (id) {
    return !success ? (
      <div className="m-3">
        {error && <div className="text-danger">{error}</div>}
        <Form
          schema={form.schema}
          uiSchema={form.uiSchema}
          validator={validator}
          onSubmit={submit}
        >
          <ReCAPTCHA
            sitekey="6Lc9qcgpAAAAAEwuoGoOZUVVu9XMwwUcNk30mzMa"
            ref={recaptchaRef}
          />
          {captchaErr && <div className="text-danger">{captchaErr}</div>}

          <button className="btn btn-primary" type="submit">
            Submit
          </button>
        </Form>
      </div>
    ) : (
      <div className="text-center">
        <h4>Thank you for submitting your query.</h4>
      </div>
    );
  } else {
    return <></>;
  }
};

export default App;
